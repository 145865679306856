import React, {Component} from 'react';
import _ from "lodash";
import PropTypes from "prop-types";

import withValidations from '../WithValidations';

import { InputContainer, Button, StyledLink, ErrorMessage, StyledColumn, FormContainer } from '../LoginPage/styles';
import CommonInputBox from '../common/CommonInputBox/CommonInputBox';

class FormComponent extends Component{
  constructor(props){
    super(props);
    this.state = {
      display: false
    }
  }
  onInputChange = (e) => {
    const {onChange,changeSubmit}= this.props;
    changeSubmit('');
    onChange(e);
  };
  togglePassword = () => {
    this.setState((prevState) => ({
      display: !prevState.display
    }));
  }
  render() {
    const{display} = this.state;
    const {showResetPassword, section, errors, touched, onSubmit, loginError, type, companyError, isSubmitted} = this.props;
    return (
      <StyledColumn marginLeft={section.title.includes('login')} marginRight={!section.title.includes('login')}>
        <span>{section.title}</span>
        <FormContainer>
          {
            type === 'login' ?
              (Object.keys(_.omitBy(errors, _.isNil)).length === 0 && isSubmitted === "user" &&
                loginError && <ul><ErrorMessage>{loginError}</ErrorMessage></ul>) :
              type === 'register' ? (Object.keys(_.omitBy(errors, _.isNil)).length === 0 &&
                isSubmitted === "company" && companyError && <ul><ErrorMessage>{companyError}</ErrorMessage></ul>) : ''
          }
          <form onSubmit={(e) => onSubmit(e)}>
            {
              section.inputFields.map((inputField, index) => (
                <InputContainer key={inputField.placeholder + index}>
                  <CommonInputBox
                    type={(inputField.type == "password") ? (display ? 'text' : inputField.type) : inputField.type}
                    placeholder={inputField.placeholder}
                    name={inputField.name}
                    onChange={(e) => this.onInputChange(e)}
                    maxLength={inputField.maxLength}
                  />
                  {(inputField.type == "password") ? display ? <i className={`fas fa-eye eye-icon`} onClick={this.togglePassword} /> : <i className="far fa-eye-slash eye-icon" onClick={this.togglePassword} /> : null}
                  {
                    inputField.placeholder === 'Company Name' ?
                      <img src="/public/images/company-icon.png" alt="company-icon"/> :
                      <i className={`fas ${inputField.icon} input-field-icon`}/>
                  }
                  {errors[inputField.name] && <ul><ErrorMessage>{errors[inputField.name]}</ErrorMessage></ul>}
                </InputContainer>
              ))
            }
            <div>
              <Button
                type="submit"
                changeColor={section.buttonText === 'register'}
                disabled={
                  Object.keys(_.omitBy(errors, _.isNil)).length > 0
                  || Object.keys(touched).length === 0 ||
                  Object.keys(touched).length < section.inputFields.length
                }
              >{section.buttonText}</Button>
            </div>
            <div>
              {
                section.link !== '' && <StyledLink onClick={showResetPassword}>{section.link}</StyledLink>
              }
            </div>
          </form>
        </FormContainer>
      </StyledColumn>
    );
  }
}

FormComponent.propTypes = {
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  section: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  showResetPassword: PropTypes.func.isRequired,
  loginError: PropTypes.string,
  type: PropTypes.string.isRequired,
  companyError: PropTypes.string,
  isSubmitted: PropTypes.string.isRequired,
  changeSubmit: PropTypes.func.isRequired,
  history: PropTypes.object
};

export default withValidations(FormComponent);
