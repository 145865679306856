import React, { Component } from "react";
import { StyledBody, StyledModal, Info, CustomInput } from "./styles";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Border } from "../EventDetails/styles";
import Spinner from "../common/Spinner";
import { ErrorMessage } from "../LoginPage/styles";
import { isValidEmail } from "../../utils/methods";
import _ from "lodash";

class ForgetCompanyPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email:"",
      errMsg:""
    };
  }

  onInputChange=(e)=>{
    this.setState({email:e.target.value});
    this.isEmailValid(e.target.value);
    this.props.setRegisterMsg()
  }

  isEmailValid=(value)=>{
    if (_.isUndefined(value) || _.isNull(value)) {
      this.setState({errMsg:'Email is required'});
      return false
    } else if (!isValidEmail(value.trim())) {
      this.setState({errMsg:'Invalid Email'});
      return false 
    }else{
      this.setState({errMsg:''});
      return true
    }
  }
  componentDidMount(){
    this.setState({errMsg:this.props.errMsg})
  }

  // componentDidUpdate(prevProps){
  //   if(prevProps.errMsg !=this.state.errMsg){
  //     this.setState({errMsg:this.props.errMsg})
  //   }

  // }

  render() {
    const { showModal, onNoClick, onYesClick, title, type, loading, registerMsg } = this.props;
    const{errMsg,email} = this.state;
    // const { name, duration, body } = challenge;
    // const { title, start_date, company_name  } = challenge;
    return (
      <StyledModal
        show={showModal}
        // onHide={this.onClose}
        dialogClassName={"info-popup"}
        backdropClassName="drop-back"
      >
        <Modal.Header style={{ background:type == "alert" && "#FD7175"}}>
          { type === "note" ? (<div>
            {" "}
            <span>{title}</span>
          </div>):
            (<div style={{display:"flex", justifyContent:"space-between", alignItems:"center", width:"100%"}}>
              {" "}
              <div style={{display:"flex", justifyContent:"center", width:"100%"}}><span>{title}</span></div>
              <div style={{cursor:"pointer"}}> <img src='/public/images/new_close_icon.png' style={{width:"18px", height:"18px"}}  onClick={onNoClick}/></div>
            </div>)}
        </Modal.Header>
        <StyledBody>
          <Info>
            {type === "note" ? (
              <React.Fragment>
                <div className="desc">
                  <span className="text">
                    Don’t Remember Company Name & Password? Just input your
                    company email below and hit the
                    <span className="req"> Send Request Button.</span>
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "25px 0px",
                    flexDirection:"column",
                    alignItems:"center"
                  }}
                >
                  <CustomInput
                    placeholder="Enter Your Company Email"
                    style={{ width: "415px", height: "60px" }}
                    onChange={(e)=>this.onInputChange(e)}
                    // onBlur={()=>onYesClick(this.state.email)}
                  />
                  {(errMsg || registerMsg) && <ul style={{display:"flex", justifyContent:"start", width:"100%"}}><ErrorMessage className="error-message">{errMsg || registerMsg}</ErrorMessage></ul>}
                </div>
                <Border
                  style={{
                    margin: "0px",
                    background: "rgba(156, 156, 156, 0.40)",
                  }}
                />

                <div className="btns">
                  <button className="btn1" disabled={loading || errMsg || !email} onClick={()=>onYesClick(this.state.email)}>
                    {loading ? <Spinner color={"white"}/> : "Send Request"}
                  </button>
                  <button className="btn2" onClick={onNoClick}>
                    Cancel
                  </button>
                </div>
              </React.Fragment>
            ) : type === "confirm" ? (
              <React.Fragment>
                <div className="desc">
                  <span className="text">
                  We have sent your Company Name and Password to register. Please check your inbox as you should receive it within 1 minute.
                  </span>
                </div>

                <div className="desc" style={{paddingBottom:"25px"}}>
                  <span className="text">
                  If do not receive the registration email, please contact your HR Team or Woliba Support at
                    <a href="mailto:support@woliba.io" target="_blank" rel="noopener noreferrer"  className="req"> support@woliba.io</a> to better assist you.
                  </span>
                </div>
              </React.Fragment>
            ) : type === "alert" ? (
              <React.Fragment>
                <div className="desc" style={{paddingBottom:"25px"}}>
                  <span className="text" style={{width:"422px"}}>Hey, we are unable to find your email in our database. Kindly contact your HR Team or Woliba Support at <a href="mailto:support@woliba.io" target="_blank" rel="noopener noreferrer"  className="req"> support@woliba.io</a> to better assist you.
                  </span>
                </div>
              </React.Fragment>
            ) : null}
          </Info>
        </StyledBody>
      </StyledModal>
    );
  }
}
ForgetCompanyPopup.defaultProps = {
  challenge: { name: "", duration: "", body: "" },
};
ForgetCompanyPopup.propTypes = {
  showModal: PropTypes.bool,
  title: PropTypes.string,
  type: PropTypes.string,
  onNoClick: PropTypes.func,
  onYesClick: PropTypes.func,
  loading:PropTypes.bool,
  errMsg:PropTypes.string,
  registerMsg:PropTypes.string,
  setRegisterMsg:PropTypes.func,
};

export default connect(null, null)(withTranslation()(ForgetCompanyPopup));
